import { HttpResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { WarehouseRepository } from "app/domains/warehouse/warehouse.repository";
import { CompanyService } from "app/info-click/domains/company/services/company.service";
import { Socket } from "ngx-socket-io";
import { Subscription } from "rxjs";

@Component({
  selector: "app-warehouse-notifier",
  templateUrl: "./warehouse-notifier.component.html",
  styleUrls: ["./warehouse-notifier.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarehouseNotifierComponent implements OnInit, OnDestroy {
  public show: boolean = false;

  private repositorySubscription: Subscription;
  private socketSubscription: Subscription;
  private companySubscription: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private warehouseRepository: WarehouseRepository,
    private socket: Socket,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.checkDefaultWarehouse();

    this.socketSubscription = this.socket
      .fromEvent(`model-saved.warehouses`)
      .subscribe({
        next: () => {
          this.checkDefaultWarehouse();
        },
      });

    this.socketSubscription = this.companyService.change().subscribe({
      next: () => {
        this.checkDefaultWarehouse();
      },
    });
  }

  ngOnDestroy(): void {
    this.repositorySubscription?.unsubscribe();
    this.socketSubscription?.unsubscribe();
    this.companySubscription?.unsubscribe();
  }

  private checkDefaultWarehouse() {
    this.repositorySubscription = this.warehouseRepository
      .search({
        active: 1,
      })
      .subscribe({
        next: (response: HttpResponse<any>) => {
          this.show = response.body.data.length === 0;
          this.cdr.markForCheck();
        },
      });
  }
}
